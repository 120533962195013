<template>
  <button
    @click="$root.togglePopUp()"
    class=" flex-grow-0 px-4 py-2 m-1 border-4 text-left  rounded-2xl text-black flex space-x-4"
    :class="border"
  >
    <p class="font-bold">
      <slot name="tag"></slot>
    </p>
    <div v-if="color" class="bg-sg-orange-500 w-6 h-6 rounded-full"></div>
    <p class="underline ">
      <slot name="content"></slot>
    </p>

    <img class=" w-6" src="../assets/icons/delete.svg" alt="Delete Symbol" />
  </button>
</template>

<script>
export default {
  props: { border: String, color: Boolean }
};
</script>
