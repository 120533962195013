<template>
  <div
    class="w-full px-4 xl:px-0 max-w-screen-sm flex flex-col items-center text-center mt-24"
    id="SgMailHeader"
  >
    <slot></slot>
    <h1
      v-if="header"
      class="text-4xl md:text-6xl  font-extrabold leading-none tracking-tight"
    >
      <slot name="header">
        Already loving it?
      </slot>
    </h1>

    <p class="mt-8 lg:mt-12 lg:text-xl font-medium max-w-md">
      <slot name="text">
        Sign in for the newsletter and be the first,
        <u>who spends the time in a better way</u>.
      </slot>
    </p>

    <sg-mail></sg-mail>
  </div>
</template>

<script>
import SgMail from "./SgMail.vue";
export default {
  components: { SgMail },
  props: { header: { type: Boolean, default: true } }
};
</script>
