<template>
  <div class="mt-12">
    <transition name="slide-fade">
      <sg-mail-message v-if="success" :response="response"></sg-mail-message>
    </transition>
    <label for="123412341234"
      >Leave the
      <b class="font-bold">
        one image per time
      </b>
      era behing:</label
    >
    <form
      id="email-form"
      name="newsletter"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      @submit.prevent="processForm"
    >
      <input type="hidden" name="form-name" value="ask-question" />
      <input
        class="mt-4  xl:-ml-4 mr-4 mb-4 px-4 w-64 py-2 rounded-full outline-none appearance-none shadow-even"
        type="email"
        name="email"
        id="123412341234"
        placeholder="Insert your mail…"
        v-model="email"
        required
      />
      <sg-button>Sign Up</sg-button>
    </form>
    <p class="mt-8 md:mt-2 text-sm text-gray-800 font-light leading-none">
      By signing up for the newsletter <br />
      you agree to our
      <router-link class="underline" to="/guidelines">guidelines</router-link>.
    </p>
    <p v-if="errors.length">
      <b class="mt-8 font-bold text-lg"
        >Please correct the following error(s):</b
      >
    </p>

    <ul>
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
import SgMailMessage from "./SgMailMessage.vue";
import SgButton from "./SgButton.vue";

export default {
  data() {
    return {
      email: null,
      success: false,
      responseMessage: null,
      errors: []
    };
  },
  methods: {
    processForm() {
      console.log("process");
      var data = new FormData();
      data.append("form-name", "newsletter");
      data.append("email", this.email);

      if (this.email) {
        fetch("/", {
          method: "POST",
          body: data
        })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            this.responseMessage = data;
            this.success = true;
          })
          .catch(error => {
            this.errors.push(error);
          });
      } else {
        this.errors.push("The email field is required");
      }
    }
  },
  computed: {
    response() {
      if (this.responseMessage.message) {
        return { message: this.responseMessage.message, error: true };
      } else {
        return { error: false };
      }
    }
  },
  components: {
    SgMailMessage,
    SgButton
  }
};
</script>
