<template>
  <div
    class="mt-24 w-full px-4 xl:px-0 lg:flex max-w-screen-sm mx-auto text-left"
  >
    <div class="w-2/3 md:w-1/3 flex-shrink-0 pr-4 mr-4 lg:mr-8">
      <h4
        v-for="(benefit, index) in benefits"
        :key="benefit.title"
        class="font-light leading-tight mb-6  cursor-pointer text-2xl "
        @click="activeBenefit = index"
        v-bind:class="{ 'underline font-bold': index == activeBenefit }"
      >
        <b class="font-bold">{{ index + 1 }}</b
        >&nbsp;{{ benefit.title }}
      </h4>
    </div>
    <p class="w-full text-xl">{{ benefits[activeBenefit].text }}</p>
  </div>
</template>

<script>
import benefits from "../content/benefits.js";

export default {
  data() {
    return {
      benefits: benefits.benefits,
      activeBenefit: 0
    };
  }
};
</script>
