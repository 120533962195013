<template>
  <div
    class="fixed inset-0 px-4 lg:px-0 z-40 bg-opacity-75 bg-sg-blue-900 flex items-center justify-center h-screen"
    style="top: 1rem"
  >
    <div
      class="bg-white pb-12 shadow-evenXl rounded-2xl opacity-100 w-full max-w-screen-sm"
    >
      <img
        class="h-6 ml-auto cursor-pointer m-8"
        src="../assets/icons/delete.svg"
        alt="Back Button"
        @click="$root.togglePopUp()"
      />
      <sg-mail-header class="-mt-4"
        ><template v-slot:header>Coming soon!</template>
        <template v-slot:text
          >We love to see that you want to test <u>Sgriffle</u>. Please sign in
          to the newsletter and we'll inform you when it's ready to
          go.</template
        >
      </sg-mail-header>
    </div>
  </div>
</template>

<script>
import SgMailHeader from "./SgMailHeader.vue";
export default {
  name: "SgPopUp",
  components: { SgMailHeader }
};
</script>
