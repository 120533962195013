import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";

import "./registerServiceWorker";
import "@/assets/style.css";
import router from "./router";
import Particles from "particles.vue";

Vue.use(Particles);
Vue.use(VueMeta);
Vue.prototype.window = window;

Vue.config.productionTip = false;

new Vue({
  data: {
    popUp: false,
  },
  methods: {
    togglePopUp() {
      this.popUp = !this.popUp;
    },
  },
  metaInfo: () => ({
    title: "Sgriffle",
    titleTemplate: "%s | The new way of Content Creation",
    htmlAttrs: {
      lang: "en",
      amp: undefined,
    },
    bodyAttrs: {
      tabIndex: 0,
    },
    meta: [
      { name: "title", content: "Sgriffle | The new way of Content Creation" },
      {
        name: "description",
        content:
          "Sgriffle is a graphic design editor, that works like a search bar. You can use it directly in the browser. And the best: it is for free",
      },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://sgriffle.com/" },
      {
        property: "og:title",
        content: "Sgriffle | The new way of Content Creation",
      },
      {
        property: "og:description",
        content:
          "Sgriffle is a graphic design editor, that works like a search bar. You can use it directly in the browser. And the best: it is for free",
      },
      {
        property: "og:image",
        content: require("./assets/header.png"),
      },
      { property: "twitter:card", content: "summary_large_image" },
      { property: "twitter:url", content: "https://sgriffle.com/" },
      {
        property: "twitter:title",
        content: "Sgriffle | The new way of Content Creation",
      },
      {
        property: "twitter:description",
        content:
          "Sgriffle is a graphic design editor, that works like a search bar. You can use it directly in the browser. And the best: it is for free",
      },
      {
        property: "twitter:image",
        content: require("./assets/header.png"),
      },
    ],
    script: [
      {
        innerHTML:
          '{ "@context": "http://www.schema.org", "@type": "Organization" }',
        type: "application/ld+json",
      },
      {
        innerHTML: '{ "body": "yes" }',
        body: true,
        type: "application/ld+json",
      },
    ],
    __dangerouslyDisableSanitizers: ["script"],
  }),

  router,
  render: (h) => h(App),
}).$mount("#app");
