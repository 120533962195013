export default {
  benefits: [
    {
      title: "Work like a Designer",
      text:
        "Sgriffle works just like a designer. Different proposals are made and presented for you. If you want to change something, do it directly. When you are satisfied, download your artwork."
    },
    {
      title: "Save your Sgriffles",
      text:
        "Found the perfect composition of your Sgiffles? Don't lose your style. Save them for your next creations. "
    },
    {
      title: "A postcard every morning",
      text:
        "You and your followers can't get enough? Let Sgriffle send you new content at regular intervals, with exactly the Sgriffles you have saved."
    }
  ]
};
