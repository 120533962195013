import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../views/LandingPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landingPage",
    component: LandingPage
  },
  {
    path: "/pricing",
    name: "pricing",
    // route level code-splitting
    // this generates a separate chunk (Pricing.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Pricing" */ "../views/Pricing.vue")
  },
  {
    path: "/guidelines",
    name: "guidelines",
    // route level code-splitting
    // this generates a separate chunk (Pricing.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Pricing" */ "../views/Guidelines.vue")
  },
  {
    path: "/image/:set/:index",
    name: "image",
    // route level code-splitting
    // this generates a separate chunk (Pricing.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Pricing" */ "../views/Image.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
