var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',[_c('Particles',{staticClass:"w-full relative h-screen -mt-24 overflow-hidden",attrs:{"id":"tsparticles","options":{
        fps_limit: 60,
        background: {
          color: {
            value: '#ffffff'
          },
          size: 'auto 100%',
          repeat: 'no-repeat',
          position: 'center'
        },
        interactivity: {
          detect_on: 'window',
          events: {
            ondiv: {
              enable: true,
              ids: 'login',
              mode: ['bubble']
            },
            onclick: {
              enable: true,
              mode: 'repulse'
            },
            onhover: {
              enable: true,
              mode: ['repulse', 'connect', 'grab']
            },
            resize: true
          },
          modes: {
            bubble: {
              distance: 4000,
              duration: 500,
              opacity: 0.2,
              size: 5,
              speed: 3
            },
            repulse: {
              distance: 200
            },
            connect: {
              distance: 200,
              lineLinked: {
                opacity: 0.2
              }
            },
            grab: {
              distance: 200,
              line_linked: {
                opacity: 1
              }
            },
            repulse: {
              distance: 200
            }
          }
        },
        particles: {
          size: {
            value: 10
          },
          number: {
            density: {
              enable: true,
              area: 2000
            },
            value: 200
          },
          collisions: {
            enabled: true,
            mode: 'bounce'
          },
          color: {
            value: ['#FF5714', '#408851', '#ECE229', '#E8AA14', '#1BA4FF']
          },
          line_linked: {
            color: '#000000',
            distance: 150,
            enable: true,
            opacity: 0,
            width: 1
          },
          shape: {
            type: 'circle'
          },
          opacity: {
            value: 1
          },
          size: {
            value: 10,
            random: true,
            anim: {
              enable: true,
              speed: 10,
              size_min: 10,
              sync: false
            }
          },
          move: {
            angle: 90,
            attract: {
              enable: false,
              rotate: {
                x: 600,
                y: 600
              }
            },
            direction: 'none',
            enable: true,
            noise: {
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0
                },
                value: 0
              },
              enable: false
            },
            outMode: 'bounce',
            random: true,
            speed: 1,
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fillColor: {
                value: '#000000'
              }
            },
            vibrate: false,
            warp: false
          }
        },
        retina_detect: true
      }}})],1),_c('div',{staticClass:"w-full mx-auto shadow-xl text-center flex flex-col items-center py-8 lg:py-12"},[_c('div',{attrs:{"id":"login"}},[_c('sg-header')],1),_c('sg-editor'),_c('sg-benefits'),_c('sg-mail-header')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }