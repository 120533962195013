<template>
  <div class="px-4 lg:px-0 w-full z-30 mt-24 lg:mt-0">
    <div
      class="flex flex-col items-center mx-auto w-full py-12  px-12 xl:px-0 max-w-screen-sm"
    >
      <h1
        class=" z-30 text-5xl md:text-6xl font-bold leading-none tracking-tight"
      >
        <p class="text-4xl md:text-6xl font-bold">
          The new way of
        </p>
        Content Creation.
      </h1>

      <p class="z-30 mt-8 text-xl lg:text-2xl max-w-md leading-tight">
        Sgiffle is a graphic editor, that works <u>like a search box</u>.
      </p>
      <sg-mail></sg-mail>
    </div></div
></template>

<script>
import SgMail from "./SgMail.vue";

export default {
  components: { SgMail }
};
</script>
