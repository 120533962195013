<template>
  <div id="app">
    <transition name="slide-fade">
      <sg-pop-up v-if="this.$root.$data.popUp"></sg-pop-up>
    </transition>
    <sg-menu></sg-menu>
    <transition appear name="slide-fade">
      <SgDsvgo v-if="dsvgo" @dsvgo="dsvgo = false"></SgDsvgo>
    </transition>
    <router-view
      style="overflow: hidden"
      v-bind:class="{
        'pointer-events-none': this.$root.$data.popUp
      }"
    />
  </div>
</template>

<script>
import SgDsvgo from "./components/SgDsvgo.vue";
import SgMenu from "./components/SgMenu.vue";
import SgPopUp from "./components/SgPopUp.vue";

export default {
  data() {
    return {
      dsvgo: true
    };
  },
  components: {
    SgDsvgo,
    SgMenu,
    SgPopUp
  }
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
