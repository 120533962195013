<template>
  <div
    class="border-4 p-4 mb-4 rounded-2xl mt-8"
    v-bind:class="{
      'border-sg-red-500': response.error,
      'border-sg-green-500': !response.error
    }"
  >
    <p v-if="!response.error" class="font-bold">
      <b class=" text-sg-red-500">Congratulation!</b> <br />
      We will inform you when it's time to shine.
    </p>
    <p v-else class="font-bold">
      <b class=" text-sg-red-500">Error!</b> <br />
      {{ response.message }}
    </p>
  </div>
</template>

<script>
export default {
  props: { response: Object }
};
</script>
