<template>
  <div
    class="bg-white rounded-2xl shadow-even w-full flex flex-wrap p-1 transition-all"
  >
    <sg-tag border="border-sg-red-500"
      ><template v-slot:tag>Feed</template
      ><template v-slot:content>Instagram</template></sg-tag
    >
    <sg-tag border="border-sg-blue-600"
      ><template v-slot:tag>Head</template
      ><template v-slot:content
        >The fastest way to become a desinger.</template
      ></sg-tag
    >
    <sg-tag border="border-sg-blue-600"
      ><template v-slot:tag>Subhead</template
      ><template v-slot:content
        >Don‘t become one. Let Sgriffle be the one.</template
      ></sg-tag
    >
    <sg-tag border="border-sg-blue-600"
      ><template v-slot:tag>Font</template
      ><template v-slot:content>Work Sans</template></sg-tag
    >

    <transition v-for="SgTag in activeSgTags" :key="SgTag.tag">
      <sg-tag :border="SgTag.border" :color="SgTag.color">
        <template v-slot:tag>{{ SgTag.tag }}</template
        ><template v-slot:content>{{ SgTag.content }}</template></sg-tag
      >
    </transition>
  </div>
</template>

<script>
import SgTag from "./SgTag.vue";
export default {
  data() {
    return {
      SgTags: [
        {
          tag: "Color",
          color: true,
          content: "#E8AA14",
          border: "border-sg-green-400"
        },
        {
          tag: "Justified",
          color: false,
          content: "Left",
          border: "border-sg-blue-600"
        }
      ]
    };
  },
  components: { SgTag },
  computed: {
    activeSgTags() {
      let activeSgTags;
      switch (this.interval) {
        case 0:
          activeSgTags = [];
          break;
        case 1:
          activeSgTags = [this.SgTags[0]];
          break;
        case 2:
          activeSgTags = [this.SgTags[0], this.SgTags[1]];
          break;
        case 3:
          activeSgTags = [this.SgTags[1]];
          break;
      }
      return activeSgTags;
    }
  },
  props: { interval: Number }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0.5;
}
.slide-fade-enter-to, .slide-fade-leave
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0.5;
}
</style>
