<template>
  <div
    class="w-full z-30 text-white mt-4 md:mt-12 lg:mt-24 px-4 xl:px-0 bg-sg-blue-900"
  >
    <div class=" mx-auto py-24  max-w-screen-sm flex flex-col items-center">
      <h2 class="font-bold text-2xl lg:text-4xl ">
        The <b class="  font-bold underline">Sgriffle</b> Editor:
      </h2>
      <h2 class="text-2xl mt-2 uppercase text-sg-red-500">Coming soon!</h2>
      <p class="mt-4 bold">
        Just insert tags, so called Sgriffles, into the bar. Immediately you
        will get fresh designed images.
      </p>
      <div class="h-xl md:h-sm">
        <sg-box class="mt-8 lg:mt-12" :interval="counter"></sg-box>
      </div>
      <div>
        <div class="grid grid-cols-3 gap-2 lg:gap-4 mt-24 sm:mt-12 lg:mt-12">
          <img
            v-for="index in 9"
            class=" rounded-lg shadow-even cursor-pointer"
            :key="index"
            :src="require('../assets/pics/' + index + '_' + counter + '.png')"
            :alt="'Pic Number' + index"
            @click="
              $router.push({
                name: 'image',
                params: { set: counter, index: index }
              })
            "
          />
        </div>
      </div>
      <h3 class="mt-8 text-2xl text-sg-red-500">
        <u>Want to edit?</u>&nbsp;Click on an image!
      </h3>
    </div>
  </div>
</template>

<script>
import SgBox from "./SgBox.vue";

export default {
  components: {
    SgBox
  },
  mounted() {
    this.startInterval();
  },
  data() {
    return {
      counter: 0
    };
  },
  methods: {
    startInterval() {
      setInterval(() => {
        this.counter = this.counter + 1;
        if (this.counter > 3) {
          this.counter = 0;
        }
      }, 2000);
    }
  }
};
</script>
