<template>
  <button
    type="submit"
    class="px-4 focus:outline-none py-2 bg-white rounded-full shadow-even font-bold text-black border-4 transition duration-200 ease-in-out transform hover:scale-110"
    :class="'border-' + this.color"
    @click="clicked"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    color: { type: String, default: "sg-red-500" },
    checkBox: Boolean,
    active: Boolean,
  },
  computed: {
    activeClass() {
      return "bg-" + this.color + " text-white border-none transform scale-110";
    },
    deactiveClass() {
      return "border-" + this.color;
    },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>
